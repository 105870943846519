import React from "react";
import { Route, Routes } from "react-router-dom";

//Admin Panel imports
import Admin from "./admin/Admin";
import AdminRegister from "./admin/SuperAdmin/AdminRegister";
import AdminHome from "./admin/AdminHome";
import UploadCourse from "./admin/NoteDepartment/UploadCourse";
import CourseList from "./admin/NoteDepartment/CourseList";
import CourseDetails from "./admin/NoteDepartment/CourseDetails";
import AddCourseMaterial from "./admin/NoteDepartment/AddCourseMaterial";
import CourseEdit from "./admin/NoteDepartment/CourseEdit";
import AddArticle from "./admin/MarketingDepartment/AddArticle";
import CreateVacancy from "./admin/MarketingDepartment/CreateVacancy";
import EnabledArticlesList from "./admin/MarketingDepartment/EnabledArticlesList";
import DisabledArticlesList from "./admin/MarketingDepartment/DisabledArticlesList";
import ArticlesDetail from "./admin/MarketingDepartment/ArticlesDetail";
import VacancyList from "./admin/MarketingDepartment/VacancyList";
import VacancyDetail from "./admin/MarketingDepartment/VacancyDetail";
import UserList from "./admin/AdmissionDepartment/UserList";
import UserDetail from "./admin/AdmissionDepartment/UserDetails";
import AdminProfile from "./admin/AdminProfile";
import ViewMessages from "./admin/SuperAdmin/ViewMessages";
import UserCourseList from "./admin/AdmissionDepartment/UserCourseList";
import AddBannerImage from "./admin/SuperAdmin/AddBannerImage";
import ViewBannerImages from "./admin/SuperAdmin/ViewBannerImages";
import AddCourseCategory from "./admin/NoteDepartment/AddCourseCategory";
import CourseCategoryList from "./admin/NoteDepartment/CourseCategoryList";
import EditCourseCategory from "./admin/NoteDepartment/EditCourseCategory";
import AddExam from "./admin/ExamDepartment/AddExam";
import ExamList from "./admin/ExamDepartment/ExamList";
import ExamEdit from "./admin/ExamDepartment/ExamEdit";
import ExamDetails from "./admin/ExamDepartment/ExamDetails";
import ExamineesList from "./admin/ExamDepartment/ExamineesList";
import SubmittedAnswers from "./admin/ExamDepartment/SubmittedAnswers";
import CreateReport from "./admin/AccountDepartment/CreateReport";
import ReportList from "./admin/AccountDepartment/ReportList";
import UserReportDetails from "./admin/AccountDepartment/UserReportDetails";
import ReportEdit from "./admin/AccountDepartment/ReportEdit";
import CourseReport from "./admin/AccountDepartment/CourseReport";

//Website imports
import Home from "../src/pages/home/Home";
import MyCourses from "./pages/courses/MyCourses";
import Courses from "./pages/courses/Courses";
import FullDetailsCourse from "./pages/courses/FullDetailsCourse";
import MyCoursesDetails from "./pages/courses/MyCoursesDetails";
import Exam from "./pages/exams/Exam";
import Result from "./pages/exams/Result";
import GiveExam from "./pages/exams/GiveExam";
import ArticlesDetails from "./pages/articles/ArticlesDetails";
import Article from "./pages/articles/Article";
import VacancyDetails from "./pages/notices/VacancyDetails";
import Notices from "./pages/notices/Notices";
import Videos from "./pages/components/Videos";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ViewProfile from "./pages/user/ViewProfile";
import Login from "./pages/user/Login";
import Register from "./pages/user/Register";
import ChangePassword from "./pages/user/ChangePassword";
import AdminRoleHome from "./admin/AdminRoleHome";
import AddQuestionBank from "./admin/ExamDepartment/AddQuestionBank";
import QuestionBankEdit from "./admin/ExamDepartment/QuestionBankEdit";
import QuestionBankDetail from "./admin/ExamDepartment/QuestionBankDetail";
import QuestionBankList from "./admin/ExamDepartment/QuestionBankList";
import CreateQuestionBankExam from "./admin/ExamDepartment/CreateQuestionBankExam";
import AdminChangePassword from "./admin/AdminChangePassword";
import AdminList from "./admin/SuperAdmin/AdminList";
import ReportDetails from "./admin/AccountDepartment/ReportDetails";
import UserRegister from "./admin/AdmissionDepartment/UserRegister";
import EnrollRequestList from "./admin/AdmissionDepartment/EnrollRequestList";
import AddTeacher from "./admin/NoteDepartment/AddTeacher";
import TeacherEdit from "./admin/NoteDepartment/TeacherEdit";
import TeacherDetails from "./admin/NoteDepartment/TeacherDetails";
import TeacherList from "./admin/NoteDepartment/TeacherList";
import WeeklyExamList from "./admin/ExamDepartment/WeeklyExamList";
import WeeklyExamEdit from "./admin/ExamDepartment/WeeklyExamEdit";
import SelectDuration from "./admin/AccountDepartment/SelectDuration";
import CourseTeacherList from "./admin/NoteDepartment/CourseTeacherList";
import AddPopUpImage from "./admin/MarketingDepartment/AddPopUpImage";
import ViewPopUpImage from "./admin/MarketingDepartment/ViewPopUpImage";
import DailyExamDetail from "./admin/ExamDepartment/DailyExamDetail";
import Testimonial from "./admin/MarketingDepartment/Testimonial";
import TestimonialList from "./admin/MarketingDepartment/TestimonialList";
import EditTestimonial from "./admin/MarketingDepartment/EditTestimonial";
import DownloadedLog from "./admin/SuperAdmin/DownloadedLog";
import DownloadedLogDetails from "./admin/SuperAdmin/DownloadedLogDetails";
import Dashboard from "./admin/SuperAdmin/Dashboard";
import PasswordCheck from "./pages/user/PasswordCheck";
import ForgetPassword from "./pages/user/ForgetPassword";
import RequestToForgetPassword from "./admin/SuperAdmin/RequestToForgetPassword";


export default function Path() {
	return (
		<div>
			<Routes>
				<Route path="/" element={<Home />} />
				{/*Courses*/}
				<Route path="/course" element={<Courses />} />
				<Route path="/course/myCourses" element={<MyCourses />} />
				<Route
					path="/course/detailscourse/:id"
					element={<FullDetailsCourse />}
				/>
				{/* <Route path="/courseMaterials/:id" element={<CourseMaterials />} /> */}
				<Route
					path="/course/myCourseDetails/:id"
					element={<MyCoursesDetails />}
				/>

				{/*exams*/}
				<Route path="/exam/:type" element={<Exam />} />
				<Route path="/exam/details/:id" element={<GiveExam />} />
				<Route path="/exam/result" element={<Result />} />
				<Route path="/exam/result/:id" element={<Result />} />

				{/*articles*/}
				<Route path="/article" element={<Article />} />
				<Route path="/article/details/:id" element={<ArticlesDetails />} />

				{/*Vacancy*/}
				<Route path="/vacancy" element={<Notices />} />
				<Route path="/vacancy/details/:id" element={<VacancyDetails />} />

				<Route path="/videos" element={<Videos />} />

				<Route path="/about" element={<About />} />
				<Route path="/contact" element={<Contact />} />

				{/*user*/}
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />
				<Route path="/viewProfile" element={<ViewProfile />} />
				<Route path="/changePassword" element={<ChangePassword />} />
				<Route path="/requestPasswordChange" element={<PasswordCheck />} />
				<Route path="/forgetPassword" element={<ForgetPassword />} />

				{/*Admin Panel Routes*/}
				<Route path="/admin" element={<Admin />}>
					<Route index element={<AdminHome />} />
					<Route path="/admin/home" element={<AdminHome />} />
					<Route path="/admin/:role/home" element={<AdminRoleHome />} />
					<Route path="/admin/adminProfile" element={<AdminProfile />} />
					<Route
						path="/admin/changePassword"
						element={<AdminChangePassword />}
					/>

					{/*Super Admin*/}
					<Route path="/admin/:role/dashboard" element={<Dashboard />} />
					<Route path="/admin/:role/register" element={<AdminRegister />} />
					<Route path="/admin/:role/viewMessages" element={<ViewMessages />} />
					<Route
						path="/admin/:role/bannerImage/add"
						element={<AddBannerImage />}
					/>
					<Route
						path="/admin/:role/bannerImage/list"
						element={<ViewBannerImages />}
					/>
					<Route path="/admin/:role/admin/list" element={<AdminList />} />
					<Route path="/admin/:role/admin/requestList" element={<RequestToForgetPassword />} />
					<Route
						path="/admin/:role/download/list"
						element={<DownloadedLog />}
					/>
					<Route
						path="/admin/:role/download/details/:id"
						element={<DownloadedLogDetails />}
					/>

					{/* Note Department */}
					<Route path="/admin/:role/course/add" element={<UploadCourse />} />
					<Route path="/admin/:role/course/list" element={<CourseList />} />
					<Route
						path="/admin/:role/course/details/:id"
						element={<CourseDetails />}
					/>
					<Route
						path="/admin/:role/course/addMaterials/:id"
						element={<AddCourseMaterial />}
					/>
					<Route path="/admin/:role/course/edit/:id" element={<CourseEdit />} />
					<Route
						path="/admin/:role/courseCategory/add"
						element={<AddCourseCategory />}
					/>
					<Route
						path="/admin/:role/courseCategory/list"
						element={<CourseCategoryList />}
					/>
					<Route
						path="/admin/:role/courseCategory/edit/:id"
						element={<EditCourseCategory />}
					/>

					<Route path="/admin/:role/teacher/add" element={<AddTeacher />} />
					<Route
						path="/admin/:role/teacher/edit/:id"
						element={<TeacherEdit />}
					/>
					<Route
						path="/admin/:role/teacher/details/:id"
						element={<TeacherDetails />}
					/>
					<Route path="/admin/:role/teacher/list" element={<TeacherList />} />
					<Route
						path="/admin/:role/course/:id/teacher/list"
						element={<CourseTeacherList />}
					/>

					{/* Marketing Department */}
					<Route path="/admin/:role/articles/post" element={<AddArticle />} />
					<Route
						path="/admin/:role/vacancy/create"
						element={<CreateVacancy />}
					/>
					<Route
						path="/admin/:role/articles/enabledList"
						element={<EnabledArticlesList />}
					/>
					<Route
						path="/admin/:role/articles/disabledList"
						element={<DisabledArticlesList />}
					/>
					<Route
						path="/admin/:role/articles/details/:id"
						element={<ArticlesDetail />}
					/>
					<Route path="/admin/:role/vacancy/list" element={<VacancyList />} />
					<Route
						path="/admin/:role/vacancy/details/:id"
						element={<VacancyDetail />}
					/>

					<Route
						path="/admin/:role/popUpImage/add"
						element={<AddPopUpImage />}
					/>
					<Route
						path="/admin/:role/popUpImage/list"
						element={<ViewPopUpImage />}
					/>
					<Route path="/admin/:role/testimonial" element={<Testimonial />} />
					<Route
						path="/admin/:role/testimonial-list"
						element={<TestimonialList />}
					/>
					<Route
						path="/admin/:role/testimonial-list/edit/:id"
						element={<EditTestimonial />}
					/>

					{/*Admission Department*/}
					<Route path="/admin/:role/user/list" element={<UserList />} />
					<Route
						path="/admin/:role/user/details/:id"
						element={<UserDetail />}
					/>
					<Route
						path="/admin/:role/user/courseEnrolled/:userId"
						element={<UserCourseList />}
					/>
					<Route path="/admin/:role/user/register" element={<UserRegister />} />
					<Route
						path="/admin/:role/user/enrollRequest/list"
						element={<EnrollRequestList />}
					/>

					{/*Exam Department*/}
					<Route path="/admin/:role/exam/add" element={<AddExam />} />
					<Route path="/admin/:role/exam/list" element={<ExamList />} />
					<Route path="/admin/:role/exam/edit/:id" element={<ExamEdit />} />
					<Route
						path="/admin/:role/exam/details/:id"
						element={<ExamDetails />}
					/>
					<Route
						path="/admin/:role/exam/:id/examinees/list"
						element={<ExamineesList />}
					/>
					<Route
						path="/admin/:role/exam/:examId/examinees/:userId/submittedAnswers"
						element={<SubmittedAnswers />}
					/>
					<Route
						path="/admin/:role/questionBank/add"
						element={<AddQuestionBank />}
					/>
					<Route
						path="/admin/:role/questionBank/edit/:id"
						element={<QuestionBankEdit />}
					/>
					<Route
						path="/admin/:role/questionBank/detail/:id"
						element={<QuestionBankDetail />}
					/>
					<Route
						path="/admin/:role/questionBank/List"
						element={<QuestionBankList />}
					/>
					<Route
						path="/admin/:role/questionBank/exam/create"
						element={<CreateQuestionBankExam />}
					/>
					<Route
						path="/admin/:role/free/exam/list"
						element={<DailyExamDetail />}
					/>
					<Route
						path="/admin/:role/course/weeklyExam/list"
						element={<WeeklyExamList />}
					/>
					<Route
						path="/admin/:role/course/weeklyExam/edit/:id"
						element={<WeeklyExamEdit />}
					/>
					{/* AccountDepartment */}
					<Route path="/admin/:role/report/add" element={<CreateReport />} />
					<Route path="/admin/:role/report/list" element={<ReportList />} />
					<Route
						path="/admin/:role/report/list/:email"
						element={<UserReportDetails />}
					/>
					<Route path="/admin/:role/report/edit/:id" element={<ReportEdit />} />
					<Route
						path="/admin/:role/report/details/:id"
						element={<ReportDetails />}
					/>
					<Route
						path="/admin/:role/courseReport/list"
						element={<CourseReport />}
					/>
					<Route
						path="/admin/:role/selectDuration/:id/:timeRange"
						element={<SelectDuration />}
					/>
				</Route>
			</Routes>
		</div>
	);
}
