import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Modal,
	Slider,
	IconButton,
	Menu,
	MenuItem,
	useMediaQuery,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useTheme } from "@mui/material/styles";
import ReactPlayer from "react-player";

const formatTime = (seconds) => {
	const minutes = Math.floor(seconds / 60);
	const secs = Math.floor(seconds % 60);
	return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
};

const VideoModal = ({ open, handleClose, videoUrl }) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

	const playerRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(true);
	const [playedSeconds, setPlayedSeconds] = useState(0);
	const [muted, setMuted] = useState(false);
	const [playbackRate, setPlaybackRate] = useState(1);
	const [duration, setDuration] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleForward = () => {
		playerRef.current.seekTo(playedSeconds + 10, "seconds");
	};

	const handleBackward = () => {
		playerRef.current.seekTo(Math.max(playedSeconds - 10, 0), "seconds");
	};

	const togglePlayPause = () => {
		setIsPlaying((prev) => !prev);
	};

	const toggleMute = () => {
		setMuted((prev) => !prev);
	};

	const handlePlaybackRateChange = (rate) => {
		setPlaybackRate(rate);
		setAnchorEl(null);
	};

	const handleFullScreen = () => {
		const element = playerRef.current.wrapper;
		if (element.requestFullscreen) {
			element.requestFullscreen();
		}
	};

	const handleSliderChange = (event, value) => {
		playerRef.current.seekTo(value, "seconds");
	};
	useEffect(() => {
		const handleKeyDown = (event) => {
			switch (event.key) {
				case " ":
					togglePlayPause();
					event.preventDefault();
					break;
				case "ArrowRight":
					handleForward();
					break;
				case "ArrowLeft":
					handleBackward();
					break;
				case "f":
					handleFullScreen();
					break;
				default:
					break;
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [togglePlayPause, handleForward, handleBackward, handleFullScreen]);

	const modalStyle = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "75%",
		height: "80vh",
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 2,
	};

	const playerSize = isSmallScreen
		? { width: "100%", height: "auto" }
		: isMediumScreen
		? { width: "50rem", height: "28rem" }
		: { width: "70rem", height: "30rem" };

	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={modalStyle}>
				<Box
					sx={{
						position: "relative",
						width: playerSize.width,
						// height: playerSize.height,
						height: "75vh",
					}}
					onContextMenu={(e) => e.preventDefault()}
				>
					<Box
						sx={{
							position: "relative",
							width: playerSize.width,
							height: playerSize.height,
						}}
						onClick={togglePlayPause}
					>
						<ReactPlayer
							url={videoUrl}
							playing={isPlaying}
							muted={muted}
							playbackRate={playbackRate}
							ref={playerRef}
							controls={false}
							onProgress={({ playedSeconds }) =>
								setPlayedSeconds(playedSeconds)
							}
							onDuration={(duration) => setDuration(duration)}
							width="100%"
							height="100%"
						/>
					</Box>
					<Box
						sx={{
							position: "absolute",
							left: 0,
							right: 0,
							bottom: 0,
							width: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							background: "rgba(0,0,0,0.7)",
							padding: "4px",
							borderRadius: "8px",
							zIndex: 2,
						}}
					>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box sx={{ color: "white", mx: 2, display: "flex"}}>
								{formatTime(playedSeconds)} / {formatTime(duration)}
							</Box>
							<Slider
								value={playedSeconds}
								max={duration}
								onChange={handleSliderChange}
								sx={{ width: "80%", mx: 2 }}
							/>
						</Box>

						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<FastRewindIcon
								onClick={handleBackward}
								sx={{
									color: "white",
									fontSize: "30px",
									cursor: "pointer",
									mx: 1,
								}}
							/>
							{isPlaying ? (
								<PauseIcon
									onClick={togglePlayPause}
									sx={{
										color: "white",
										fontSize: "30px",
										cursor: "pointer",
										mx: 1,
									}}
								/>
							) : (
								<PlayArrowIcon
									onClick={togglePlayPause}
									sx={{
										color: "white",
										fontSize: "30px",
										cursor: "pointer",
										mx: 1,
									}}
								/>
							)}
							<FastForwardIcon
								onClick={handleForward}
								sx={{
									color: "white",
									fontSize: "30px",
									cursor: "pointer",
									mx: 1,
								}}
							/>
							<IconButton onClick={toggleMute} sx={{ color: "white", mx: 1 }}>
								{muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
							</IconButton>
							<IconButton
								onClick={handleFullScreen}
								sx={{ color: "white", mx: 1 }}
							>
								<FullscreenIcon />
							</IconButton>
							<IconButton
								sx={{ color: "white", mx: 1 }}
								onClick={(e) => setAnchorEl(e.currentTarget)}
							>
								{playbackRate}x
							</IconButton>
							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={() => setAnchorEl(null)}
							>
								{[0.5, 1, 1.5, 2].map((rate) => (
									<MenuItem
										key={rate}
										onClick={() => handlePlaybackRateChange(rate)}
									>
										{rate}x
									</MenuItem>
								))}
							</Menu>
						</Box>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default VideoModal;
