import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import {
  TextField,
  Button,
  Typography,
  Container,
  MenuItem,
  InputLabel,
  Select,
  Chip,
  CircularProgress,
  Box,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { fetchRoles } from "../adminComponents/Api/Roles";
import { toast, ToastContainer } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const AdminLoginForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "5px",
});

const initialFormState = {
  name: "",
  email: "",
  password: "",
  mobileNumber: "",
  roles: [],
};

const AdminRegister = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const nav = useNavigate();
  const [rolesOptions, setRolesOptions] = useState([]);
  const [cookies] = useCookies(["adminToken", "adminRoles"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
  const {
    formData,
    setFormData,
    setErrors,
    errors,
    handleChange,
    validateForm,
  } = useFormValidation(initialFormState);

  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const roles = await fetchRoles();
      setRolesOptions(roles);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("mobileNumber", formData.mobileNumber);
      data.append("email", formData.email);
      data.append("password", formData.password);
      formData.roles.forEach((role) => {
        data.append("roles", role.name);
      });
      setLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/api/v1/registerAdmin`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
          body: data,
        });

        if (response.ok) {
          const userData = await response.json();
          toast.success(userData.message);
          setFormData(initialFormState);
          setLoading(false);
        } else {
          const error = await response.json();
          setError(error.message || "Registration failed");
          setLoading(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  return (
    <>
      <ToastContainer position="top-center" autoClose="2000" />
      <Container maxWidth="xs">
        <AdminLoginForm onSubmit={handleSubmit}>
          <Typography variant="h2" gutterBottom>
            Register New Admin
          </Typography>
          {error && (
            <Typography variant="subtitle2" color="error" gutterBottom>
              {error}!!
            </Typography>
          )}
          <Box width="100%" marginBottom="20px">
            <TextField
              label="Name"
              variant="outlined"
              name="name"
              fullWidth
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
          </Box>
          <Box width="100%" marginBottom="20px">
            <TextField
              label="Mobile Number"
              variant="outlined"
              name="mobileNumber"
              fullWidth
              value={formData.mobileNumber}
              onChange={handleChange}
            />
            {errors.mobileNumber && <span style={{ color: "red" }}>{errors.mobileNumber}</span>}
          </Box>
          <Box width="100%" marginBottom="20px">
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              fullWidth
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email}</span>
            )}
          </Box>
          <Box width="100%" marginBottom="20px">
            <TextField
              label="Password"
              name="password" 
              type={showPassword ? "text" : "password"} // Toggle between text and password
              variant="outlined"
              fullWidth
              // margin="normal"
              value={formData.password}
              onChange={handleChange} // Update the formData state
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
             {errors.password && (
              <span style={{ color: "red" }}>{errors.password}</span>
            )}
          </Box>

          <Box width="100%" marginBottom="20px">
            <InputLabel id="multiple-select-label">Roles</InputLabel>
            <Select
              labelId="multiple-select-label"
              name="roles"
              multiple
              value={formData.roles}
              onChange={handleChange}
              displayEmpty
              input={<OutlinedInput id="select-multiple-chip" label="Roles" />}
              renderValue={(selected) =>
                selected.length === 0 ? (
                  <em>--Select Roles--</em>
                ) : (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value, index) => (
                      <Chip key={index} label={value.name} />
                    ))}
                  </Box>
                )
              }
            >
              {rolesOptions &&
                rolesOptions.map((role, index) => (
                  <MenuItem key={index} value={role}>
                    {role.name
                      .toLowerCase()
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                  </MenuItem>
                ))}
            </Select>
            {errors.roles && (
              <span style={{ color: "red" }}>{errors.roles}</span>
            )}
          </Box>
          <Button type="submit" variant="contained" color="secondary">
              {loading ? (
                  <Box
                      sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "10px",
                      }}
                  >
                      <CircularProgress />
                  </Box>
              ) : (
                  <Box >Register</Box>
              )}
          </Button>
        </AdminLoginForm>
      </Container>
    </>
  );
};

export default AdminRegister;
