import React, { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import "../../Styles/Components/Navbar.scss";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";
// import logo from "./Images/trendingCourse.jpg";
import logo from "./Images/DemoLogo.jpg";
import useLogout from "../../Hooks/useLogout";

const Navbar = () => {
	// const hamburgerRef = useRef();
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);

	const [examMenu, setExamMenu] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	const [cookies, setCookie, removeCookie] = useCookies([
		"userToken",
		"firstName",
	]);
	const [token, setToken] = useState(cookies.userToken || "");
	const [firstName, setFirsName] = useState(cookies.firstName || "");
	const handleLogout = useLogout();

	useEffect(() => {
		setToken(cookies.userToken || "");
		setFirsName(cookies.firstName || "");
	}, [cookies]);

	const toggleDropdown = () => {
		setIsOpen(!isOpen); // Toggle the state directly without console.log
		// console.log(`IS OPEN ${isOpen}`);
	};

	// for closing userProfile
	const menuRef = useRef(null);
	useEffect(() => {
		const handler = (e) => {
			if (!menuRef.current.contains(e.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, []);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 1056) {
				setIsMobile(false);
			}
		};

		// Initial check
		handleResize();

		// Listen for resize events
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	//for opning and closing to navba as responsive
	const hambugerdown = () => {
		setIsMobile(!isMobile);
	};

	const hamburgerRef = useRef(null);
	useEffect(() => {
		const handler2 = (e) => {
			if (!hamburgerRef.current.contains(e.target)) {
				setIsMobile(false);
			}
		};
		document.addEventListener("mousedown", handler2);
		return () => {
			document.removeEventListener("mousedown", handler2);
		};
	}, []);
	function handleClick() {
		window.scrollTo(0, 0);
		setIsMobile(false);
	}

	return (
		<>
			<nav>
				<div className="navbar">
					<div className="brand-title">
						<NavLink to="/">
							<img src={logo} alt="logo" />
						</NavLink>
					</div>
					<div className="hambuger">
						<span className="hamburger-icon" onClick={() => hambugerdown()}>
							<RxHamburgerMenu />
						</span>
						<span className="hamburger-logo">
							<NavLink to="/">
								<img src={logo} alt="logo" />
							</NavLink>
						</span>
					</div>

					<div
						className={isMobile ? "navbar-links-mobile" : "navbar-links"}
						ref={hamburgerRef}
					>
						<ul>
							<li
								onClick={() => {
									handleClick();
								}}
							>
								<NavLink to="/">Home</NavLink>
							</li>
							<li
								onClick={() => {
									handleClick();
								}}
							>
								<NavLink to="/course">Courses</NavLink>
							</li>
							<li
								onClick={() => {
									handleClick();
								}}
							>
								<NavLink to="/article">Articles</NavLink>
							</li>
							<li
								className="exam-menu"
								onMouseEnter={() => setExamMenu(true)}
								onMouseLeave={() => setExamMenu(false)}
							>
								<span>Exam</span>
								{examMenu && (
									<ul className="exam-dropdown">
										<li
											onClick={() => {
												handleClick();
											}}
										>
											<NavLink to="/exam/daily">Daily</NavLink>
										</li>
										<li
											onClick={() => {
												handleClick();
											}}
										>
											<NavLink to="/exam/weekly">Weekly</NavLink>
										</li>
										<li
											onClick={() => {
												handleClick();
											}}
										>
											<NavLink to="/exam/free">Free</NavLink>
										</li>
									</ul>
								)}
							</li>
							<li
								onClick={() => {
									handleClick();
								}}
							>
								<NavLink to="/vacancy">Vacancies</NavLink>
							</li>
							<li
								onClick={() => {
									handleClick();
								}}
							>
								<NavLink to="/about">About Us</NavLink>
							</li>
							<li
								onClick={() => {
									handleClick();
								}}
							>
								<NavLink to="/contact">Contact Us</NavLink>
							</li>
						</ul>
					</div>

					<div className="btn">
						{!token ? (
							<div>
								<NavLink to="/login">
									<button>Login</button>
								</NavLink>
							</div>
						) : (
							<div className="userWrapper">
								<div className="userName">
									Hello, <span>{firstName}</span>
								</div>
								<div className="user" onClick={toggleDropdown}>
									<FiUser />
								</div>
							</div>
						)}

						<div ref={menuRef}>
							{isOpen && (
								<div className="profile-dropdown">
									<div className="dropdown-content" onClick={toggleDropdown}>
										<NavLink to="/viewProfile">View Profile</NavLink>
										<br />
										<NavLink to="/course/myCourses" onClick={() => handleClick}>
											My Courses
										</NavLink>
										<div className="logout" onClick={handleLogout}>
											Logout
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<Outlet />
			</nav>
		</>
	);
};

export default Navbar;
