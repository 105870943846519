import React, { useContext, useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../subcomponents/Context";
import { useCookies } from "react-cookie";
import useFormValidation from "../../Hooks/useFormValidation";
import { IoArrowBackSharp } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import { IoHomeOutline } from "react-icons/io5";
import "../../Styles/User/Login.scss";

export default function PasswordCheck() {
	const nav = useNavigate();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const initialFormState = {
		email: "",
	};

	const { formData, setErrors, errors, handleChange, validateForm } =
		useFormValidation(initialFormState);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			setLoading(true);
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/auth/request-password-reset?email=${formData.email}`,
					{
						method: "POST",
					}
				);
				if (response.ok) {
					const data = await response.json();
					toast.success(data.message);
					setLoading(false);
					if (
						data.message ===
						"Password reset request is already approved for this user."
					) {
						nav("/forgetPassword", { state: { email: `${formData.email}` } });
					}
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log("Fetch error:", error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	const handleBack = () => {
		nav(-1);
	};

	const go_to_home = () => {
		nav("/");
	};
	return (
		<>
			<ToastContainer position="top-center" autoClose={2000} />
			<div className="main_container">
				<div className="back_login">
					<button onClick={handleBack}>
						<IoArrowBackSharp />
					</button>
					<h3>Request Password Change</h3>
					<button onClick={go_to_home}>
						<IoHomeOutline />
					</button>
				</div>
				<div className="loging_container">
					<form action="" onSubmit={handleSubmit}>
						<div className="label_input">
							<label htmlFor="">Email</label>
							<br />
							<input
								type="text"
								className="logInput"
								placeholder="Enter your email"
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
							{errors.email && <span className="error">{errors.email}</span>}
						</div>
						{error && (
							<p style={{ color: "red", textAlign: "center" }}>{error}</p>
						)}
						<button>
							{loading ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "10px",
									}}
								>
									<CircularProgress />
								</Box>
							) : (
								<div>Request</div>
							)}
						</button>
					</form>
				</div>
			</div>
		</>
	);
}
