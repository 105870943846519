import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { use } from "react";

const AdminLoginForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #ccc",
  padding: "20px",
  borderRadius: "5px",
});

const initialFormState = {
  files: [],
  youtubeLink: "",
};

const AddCourseMaterial = () => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // Backend error
  const [cookies] = useCookies(["adminToken", "adminRoles"]);
  const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
  const { id } = useParams();
  const {
    formData,
    setFormData,
    handleFilesChange,
    handleChange,
    setErrors,
    errors,
  } = useFormValidation(initialFormState);

  useEffect(() => {
    setAdminToken(cookies.adminToken || "");
  }, [cookies]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    // Append files if any are selected
    formData.files.forEach((file) => {
      data.append("files", file);
    });

    // Append the YouTube link if provided
    if (formData.youtubeUrls) {
      data.append("youtubeUrls", formData.youtubeUrls);
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/courses/${id}/uploadStudyMaterial`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
          body: data,
        }
      );

      const result = await response.json();

      if (response.ok) {
        toast.success(result.message);
        setLoading(false);
        setFormData(initialFormState);
      } else {
        setError(result.message || "An error occurred");
        setLoading(false);
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again.");
      setLoading(false);
    }
  };

  function Back() {
    nav(-1);
  }
  return (
    <>
      <ToastContainer position="top-center" autoClose="2000" />
      <Container maxWidth="xs">
        <Button
          onClick={() => Back()}
          variant="outlined"
          color="secondary"
          style={{ marginBottom: "2rem" }}
        >
          Back
        </Button>
        <AdminLoginForm onSubmit={handleSubmit}>
          <Typography variant="h2" gutterBottom>
            Add New Course Material
          </Typography>
          {error && (
            <Box
              sx={{
                //   backgroundColor: "#ffdddd",
                padding: "10px",
                marginBottom: "10px",
                marginTop: "10px",
                border: "1px solid #ff0000",
                borderRadius: "5px",
              }}
            >
              <Typography variant="subtitle2" color="error">
                {error}
              </Typography>
            </Box>
          )}
          <Box width="100%" marginBottom="20px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
                Select Files:
              </Typography>
              <input
                id="file-upload"
                type="file"
                multiple
                onChange={handleFilesChange}
                accept="application/pdf,video/*"
                style={{ display: "none" }}
                name="files"
              />
              <label htmlFor="file-upload">
                <Button variant="outlined" component="span">
                  Select
                </Button>
              </label>
            </Box>
            {formData.files &&
              formData.files.map((material) => (
                <Typography
                  key={material.name}
                  variant="body2"
                  sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
                >
                  {material.name}
                </Typography>
              ))}
          </Box>
          <Box width="100%" marginBottom="20px">
            <TextField
              fullWidth
              id="youtubeUrls"
              name="youtubeUrls"
              label="YouTube Video Link"
              variant="outlined"
              value={formData.youtubeUrls}
              onChange={handleChange}
            />
          </Box>
          <Button type="submit" variant="contained" color="secondary">
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box>Add Material</Box>
            )}
          </Button>
        </AdminLoginForm>
      </Container>
    </>
  );
};

export default AddCourseMaterial;
