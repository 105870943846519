// hooks/useLogout.js
import { useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import BASE_URL from "../subcomponents/Config";

const useLogout = () => {
	const navigate = useNavigate();
	const [cookies, , removeCookie] = useCookies([
		"userToken",
		"userRole",
		"userId",
		"videoDownload",
		"mediaPlayerDownloaded",
		"firstName",
	]);

	const handleLogout = async () => {
		// Remove cookies
		const cookiesToRemove = [
			"userToken",
			"userRole",
			"userId",
			"videoDownload",
			"mediaPlayerDownloaded",
			"firstName",
		];
		try {
			const response = await fetch(`${BASE_URL}/api/v1/auth/logout`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${cookies.userToken}`,
				},
			});

			if (response.ok) {
				cookiesToRemove.forEach((cookie) =>
					removeCookie(cookie, { path: "/" })
				);

				// Navigate to the login page
				navigate("/");
			} else {
				console.error("Logout API failed");
			}
		} catch (error) {
			console.error("Logout API error:", error.message);
		}

		// Optionally clear token state if needed
	};

	return handleLogout;
};

export default useLogout;
