import React, { useState, useEffect } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { styled } from "@mui/material/styles";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../adminComponents/Pagination/Pagination";

const PaymentForm = styled("form")({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "5px",
	borderRadius: "5px",
});

export default function EnrollRequestList() {
	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		if (adminToken) {
			getData();
		}
	}, [currentPage, adminToken]);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/password-request-user?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);

			if (response.ok) {
				const apiData = await response.json();
				setUsers(apiData.users);
				setTotalPages(apiData.totalPages);
			} else {
				const error = await response.json();
				setError(error.message);
			}
		} catch (err) {
			console.error("Error fetching data:", err);
			setError("An unexpected error occurred.");
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async (userId) => {
		try {
			setLoading(true);
			const response = await fetch(
				`${BASE_URL}/api/v1/approve-password-reset/${userId}`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
						"Content-Type": "application/json",
					},
				}
			);

			if (response.ok) {
				const { message } = await response.json();
				toast.success(message);
				getData();
			} else {
				const error = await response.json();
				toast.error(error.message);
			}
		} catch (error) {
			console.error("An error occurred:", error.message);
			toast.error("An unexpected error occurred.");
		} finally {
			setLoading(false);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<Box>
			<ToastContainer position="top-center" autoClose={2000} />
			<Card variant="outlined">
				<CardContent>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant="h3">Forget Password Requested List</Typography>
					</Box>
					<Box sx={{ overflow: { xs: "auto", sm: "unset" } }}>
						<Table sx={{ mt: 3 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Email
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Mobile Number
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Requested Date
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											Allow
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.map((user) => (
									<TableRow key={user.userId}>
										<TableCell>{user.firstName} {user.lastName}</TableCell>
										<TableCell>{user.email}</TableCell>
										<TableCell>{user.mobileNumber}</TableCell>
										<TableCell>{user.requestDate}</TableCell>
										<TableCell align="center">
											<Chip
												label="Allow"
												color="primary"
												onClick={() => handleSubmit(user.userId)}
												sx={{ cursor: "pointer" }}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{loading && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						)}
						{error && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<Typography color="error">{error}</Typography>
							</Box>
						)}
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}


