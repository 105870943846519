import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../adminComponents/admin.scss";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableRow,
	CircularProgress,
	Button,
	Chip,
	Modal,
	IconButton,
} from "@mui/material";
import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDialog from "../adminComponents/DialogBox/Confirm.js";
import useTrending from "../../Hooks/useTrending.js"; // Adjust the import path as needed
import BASE_URL from "../../subcomponents/Config.jsx";
import { SignalWifiStatusbarConnectedNoInternet4Outlined } from "@mui/icons-material";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

const tablevalue = [
	{ title: "Course Title", value: "title" },
	{ title: "Course Details", value: "details" },
	{ title: "Information", value: "information" },
	{ title: "Category Title", value: "categoryTitle" },
	{ title: "Meeting Link", value: "meetingLink" },
	{ title: "Course Fee", value: "courseFee" },
	{ title: "Weekly Exam Date", value: "weeklyExamDate" },
];

export default function CourseDetails() {
	const { id, role } = useParams();
	const [data, setData] = useState([]);
	const [videos, setVideos] = useState([]);
	const [notes, setNotes] = useState([]);
	const [youtubeUrls, setYoutubeUrls] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies] = useCookies(["adminToken"]);
	const nav = useNavigate();
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const [open, setOpen] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const [deleteName, setDeleteName] = useState("");

	const handleOpenDialog = (id, name) => {
		setDialogOpen(true);
		setDeleteId(id);
		setDeleteName(name);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
		setDeleteId(null);
		setDeleteName("");
	};

	const handleOpen = (videoUrl) => {
		setSelectedVideo(videoUrl);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedVideo(null);
	};

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleDelete = async () => {
		setLoading(true);
		if (deleteName === "course") {
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/course/delete/${deleteId}`,
					{
						method: "DELETE",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
					}
				);
				if (response.ok) {
					const apiData = await response.json();
					setLoading(false);
					handleCloseDialog();
					// toast.success(apiData.message);
					console.log(apiData.message);
					nav(-1);
				} else {
					const error = await response.json();
					setError(error.message);
					handleCloseDialog();
					setLoading(false);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		}
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/deleteStudyMaterial/${deleteId}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setLoading(false);
				handleCloseDialog();
				toast.success(apiData.message);
				getData();
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/course/admin/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setData(apiData.course);
				setVideos(apiData.course.videos);
				setNotes(apiData.course.notes);
				setYoutubeUrls(apiData.course.youtubeUrls);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	function handleEdit() {
		nav(`/admin/NOTE_DEPARTMENT/course/edit/${id}`);
	}
	function handleTeacherslist() {
		nav(`/admin/NOTE_DEPARTMENT/course/${id}/teacher/list`);
	}

	function handleAddMaterials() {
		nav(`/admin/NOTE_DEPARTMENT/course/addMaterials/${id}`);
	}

	function Back() {
		nav(-1);
	}
	const { handleTrendingChange, trendingLoading, trendingError } = useTrending(
		adminToken,
		data,
		getData
	);

	return (
		<Box>
			<ToastContainer position="top-center" autoClose="2000" />
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem", marginLeft: "1.15rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<Box style={{ display: "flex", justifyContent: "space-between" }}>
						<Box style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h3">Course Details</Typography>
						</Box>
						{role === "NOTE_DEPARTMENT" ? (
							<Box>
								<Chip
									sx={{
										backgroundColor: "primary.main",
										color: "#fff",
										mr: "8px",
									}}
									size="medium"
									label="Edit"
									onClick={() => handleEdit(id)}
								/>
								<Chip
									sx={{
										pl: "4px",
										pr: "4px",
										backgroundColor: "error.main",
										color: "#fff",
										fontSize: "0.9rem",
									}}
									size="medium"
									label="Delete"
									onClick={() => handleOpenDialog(id, "course")}
								/>
							</Box>
						) : null}
					</Box>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							{!loading && data ? (
								<>
									<TableBody>
										{tablevalue &&
											tablevalue.map((table, index) => (
												<TableRow key={index}>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{table.title}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{data[table.value]}
														</Typography>
													</TableCell>
												</TableRow>
											))}
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Picture
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													<img
														src={data.imageUrl}
														alt=""
														style={{
															width: "auto",
															height: "15rem",
														}}
													/>
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Demo Video
												</Typography>
											</TableCell>
											<TableCell>
												<ReactPlayer
													url={data.demoVideoUrl}
													controls={true}
													config={{
														file: {
															attributes: {
																controlsList: "nodownload",
															},
														},
													}}
													width="22rem"
													height="auto"
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Syllabus PDF
												</Typography>
											</TableCell>
											<TableCell>
												<NavLink
													to={data.syllabusUrl}
													target="_blank"
													style={{ textDecoration: "none", color: "black" }}
												>
													<Typography
														sx={{
															fontSize: "15px",
															fontWeight: "500",
															paddingLeft: "0",
														}}
													>
														{data.syllabusName}
													</Typography>
												</NavLink>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Teachers
												</Typography>
											</TableCell>
											<TableCell>
												<Chip
													sx={{
														pl: "4px",
														pr: "4px",
														backgroundColor: "secondary.main",
														color: "#fff",
													}}
													size="small"
													label="View List"
													onClick={() => handleTeacherslist()}
												/>
											</TableCell>
										</TableRow>
										{role === "NOTE_DEPARTMENT" ? (
											<TableRow>
												<TableCell>Trending</TableCell>
												<TableCell>
													<Chip
														sx={{
															pl: "4px",
															pr: "4px",
															backgroundColor: data.trending
																? "error.main"
																: "primary.main",
															color: "#fff",
														}}
														size="small"
														label={
															data.trending ? "Remove Trending" : "Set Trending"
														}
														onClick={() => handleTrendingChange()}
													/>
												</TableCell>
											</TableRow>
										) : null}
										{role === "NOTE_DEPARTMENT" ? (
											<>
												<TableRow>
													<TableCell
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<Typography variant="h3">
															Materials Detail
														</Typography>
													</TableCell>
													<TableCell align="right">
														<Chip
															sx={{
																backgroundColor: "success.main",
																color: "#fff",
															}}
															size="medium"
															label="Add Materials"
															onClick={() => handleAddMaterials(id)}
														/>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															Notes
														</Typography>
													</TableCell>
													{notes &&
														notes.map((note, index) => (
															<Box
																key={index}
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																	alignItems: "center",
																	marginBottom: "10px",
																}}
															>
																<TableCell>
																	<NavLink
																		to={note.noteUrl}
																		target="_blank"
																		style={{ textDecoration: "none" }}
																	>
																		<Typography
																			sx={{
																				fontSize: "15px",
																				fontWeight: "500",
																				color: "black",
																			}}
																		>
																			{note.originalFileName}
																		</Typography>
																	</NavLink>
																</TableCell>

																<TableCell align="center">
																	<Chip
																		sx={{
																			pl: "4px",
																			pr: "4px",
																			backgroundColor: "error.main",
																			color: "#fff",
																		}}
																		size="small"
																		label="Delete"
																		onClick={() =>
																			handleOpenDialog(note.id, "note")
																		}
																	/>
																</TableCell>
															</Box>
														))}
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															Videos
														</Typography>
													</TableCell>
													<>
														{videos &&
															videos.map((video, index) => (
																<Box
																	key={index}
																	style={{
																		display: "flex",
																		justifyContent: "space-between",
																		alignItems: "center",
																		marginBottom: "10px",
																	}}
																>
																	<TableCell>
																		<Typography
																			sx={{
																				fontSize: "15px",
																				fontWeight: "500",
																				cursor: "pointer",
																			}}
																			onClick={() => handleOpen(video.noteUrl)}
																		>
																			{video.originalFileName}
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Chip
																			sx={{
																				pl: "4px",
																				pr: "4px",
																				backgroundColor: "error.main",
																				color: "#fff",
																			}}
																			size="small"
																			label="Delete"
																			onClick={() =>
																				handleOpenDialog(video.id, "video")
																			}
																		/>
																	</TableCell>
																</Box>
															))}

														<ConfirmDialog
															open={dialogOpen}
															title="Confirm Action"
															content={`Are you sure you want to delete this ${deleteName}?`}
															onClose={() => handleCloseDialog()}
															onConfirm={() => handleDelete()}
														/>
														<Modal
															open={open}
															onClose={handleClose}
															aria-labelledby="modal-title"
															aria-describedby="modal-description"
														>
															<Box sx={modalStyle}>
																<IconButton
																	aria-label="close"
																	onClick={handleClose}
																	sx={{
																		position: "absolute",
																		right: 8,
																		top: 8,
																		color: (theme) => theme.palette.grey[500],
																	}}
																>
																	<CloseIcon />
																</IconButton>
																{selectedVideo && (
																	<ReactPlayer
																		url={selectedVideo}
																		controls
																		width="100%"
																		height="100%"
																	/>
																)}
															</Box>
														</Modal>
													</>
												</TableRow>

												{/* form youtubeUrls */}
												<TableRow>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															YouTube Videos URL
														</Typography>
													</TableCell>
													<>
														{youtubeUrls &&
															youtubeUrls.map((url, index) => (
																<Box
																	key={index}
																	style={{
																		display: "flex",
																		justifyContent: "space-between",
																		alignItems: "center",
																		marginBottom: "10px",
																	}}
																>
																	<TableCell>
																		<Typography
																			sx={{
																				fontSize: "15px",
																				fontWeight: "500",
																				cursor: "pointer",
																				color: "blue",
																				textDecoration: "underline",
																			}}
																			component="a"
																			href={url.uploadedFileName}
																			target="_blank"
																			rel="noopener noreferrer"
																		>
																			{url.originalFileName}
																		</Typography>
																	</TableCell>
																	<TableCell align="center">
																		<Chip
																			sx={{
																				pl: "4px",
																				pr: "4px",
																				backgroundColor: "error.main",
																				color: "#fff",
																			}}
																			size="small"
																			label="Delete"
																			onClick={() =>
																				handleOpenDialog(
																					url.id,
																					"youtube video url"
																				)
																			}
																		/>
																	</TableCell>
																</Box>
															))}

														<ConfirmDialog
															open={dialogOpen}
															title="Confirm Action"
															content={`Are you sure you want to delete this ${deleteName}?`}
															onClose={() => handleCloseDialog()}
															onConfirm={() => handleDelete()}
														/>
														<Modal
															open={open}
															onClose={handleClose}
															aria-labelledby="modal-title"
															aria-describedby="modal-description"
														>
															<Box sx={modalStyle}>
																<IconButton
																	aria-label="close"
																	onClick={handleClose}
																	sx={{
																		position: "absolute",
																		right: 8,
																		top: 8,
																		color: (theme) => theme.palette.grey[500],
																	}}
																>
																	<CloseIcon />
																</IconButton>
																{selectedVideo && (
																	<ReactPlayer
																		url={selectedVideo}
																		controls
																		width="100%"
																		height="100%"
																	/>
																)}
															</Box>
														</Modal>
													</>
												</TableRow>

												{/* end of youtubeUrls */}
											</>
										) : null}
									</TableBody>
								</>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									<CircularProgress />
								</Box>
							)}
							{error || trendingError ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error || trendingError}
								</Box>
							) : null}
						</Table>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
