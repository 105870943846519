export const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  ctx.drawImage(
    image,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(new File([blob], "croppedImage.jpg", { type: "image/jpeg" }));
    }, "image/jpeg");
  });
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (err) => reject(err));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });






// import React, { useState, useRef, useEffect } from "react";
// import { Stage, Layer, Image, Rect, Circle } from "react-konva";

// export default function CustomCropper({ imageSrc, onSave }) {
//   const [image, setImage] = useState(null);
//   const [cropArea, setCropArea] = useState({
//     x1: 50,
//     y1: 50,
//     x2: 200,
//     y2: 200,
//   });

//   const imageRef = useRef(null);
//   const stageRef = useRef(null);

//   useEffect(() => {
//     const img = new window.Image();
//     img.src = imageSrc;
//     img.onload = () => setImage(img);
//   }, [imageSrc]);

//   const handleDrag = (corner, position) => {
//     setCropArea((prev) => ({ ...prev, [corner]: position }));
//   };

//   const handleSaveCrop = () => {
//     const layer = stageRef.current.getLayer();
//     const canvas = layer.getCanvas()._canvas;
//     const context = canvas.getContext("2d");

//     const cropX = Math.min(cropArea.x1, cropArea.x2);
//     const cropY = Math.min(cropArea.y1, cropArea.y2);
//     const cropWidth = Math.abs(cropArea.x2 - cropArea.x1);
//     const cropHeight = Math.abs(cropArea.y2 - cropArea.y1);

//     const croppedCanvas = document.createElement("canvas");
//     const croppedContext = croppedCanvas.getContext("2d");
//     croppedCanvas.width = cropWidth;
//     croppedCanvas.height = cropHeight;

//     croppedContext.drawImage(
//       canvas,
//       cropX,
//       cropY,
//       cropWidth,
//       cropHeight,
//       0,
//       0,
//       cropWidth,
//       cropHeight
//     );

//     croppedCanvas.toBlob((blob) => {
//       onSave(blob);
//     }, "image/png");
//   };

//   return (
//     <>
//       <Stage width={window.innerWidth} height={window.innerHeight} ref={stageRef}>
//         <Layer>
//           {image && <Image image={image} x={0} y={0} />}
//           <Rect
//             x={Math.min(cropArea.x1, cropArea.x2)}
//             y={Math.min(cropArea.y1, cropArea.y2)}
//             width={Math.abs(cropArea.x2 - cropArea.x1)}
//             height={Math.abs(cropArea.y2 - cropArea.y1)}
//             stroke="red"
//             strokeWidth={2}
//           />
//           {["x1", "y1", "x2", "y2"].map((corner, index) => (
//             <Circle
//               key={index}
//               x={cropArea[corner === "x1" || corner === "x2" ? corner : "x1"]}
//               y={cropArea[corner === "y1" || corner === "y2" ? corner : "y1"]}
//               radius={8}
//               fill="blue"
//               draggable
//               onDragMove={(e) =>
//                 handleDrag(
//                   corner,
//                   e.target.getAttr(corner[0] === "x" ? "x" : "y")
//                 )
//               }
//             />
//           ))}
//         </Layer>
//       </Stage>
//       <button onClick={handleSaveCrop}>Save Crop</button>
//     </>
//   );
// }
