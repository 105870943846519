import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Path from "./Path";
import "react-toastify/dist/ReactToastify.css";
import { Context } from "./subcomponents/Context";
import MessageIcon from "./pages/components/MessageIcon";
import VisitorTracker from "./pages/components/VisitorTracker";
import useLogout from "./Hooks/useLogout";
import { useCookies } from "react-cookie";

const App = () => {
	const [message, setMessage] = useState("");
	const location = useLocation();
	const [cookies] = useCookies(["userToken"]);

	return (
		<div className="App">
			<VisitorTracker />
			<Context.Provider
				value={{
					message,
					setMessage,
				}}
			>
				{location.pathname !== "/contact" &&
					!location.pathname.startsWith("/admin") && <MessageIcon />}

				<Path />
			</Context.Provider>
		</div>
	);
};

export default App;
