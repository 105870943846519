import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress,
	Chip,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

const ViewMessages = () => {
	const [messages, setMessages] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(8); // Number of items per page
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	const getData = async () => {
		setLoading(true);
		try {
			// Pass currentPage and itemsPerPage to the API
			const response = await fetch(
				`${BASE_URL}/api/v1/contactMeList?page=${currentPage}&limit=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setMessages(apiData.contactMe);
				setTotalPages(Math.ceil(apiData.totalItems / itemsPerPage));
			} else {
				const error = await response.json();
				setError(error.message);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setError("An error occurred while fetching data.");
		}
		setLoading(false);
	};

	const handleDelete = async (id) => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/contact/delete/${id}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				toast.success(apiData.message);
				getData(); // Refresh the data
			} else {
				const error = await response.json();
				setError(error.message);
			}
		} catch (error) {
			console.error("Error deleting data:", error);
		}
		setLoading(false);
	};

	const renderPagination = () => {
		const pageNumbers = [];
		const maxVisiblePages = 3;
	
		// Add the first pages
		if (currentPage > maxVisiblePages + 1) {
			pageNumbers.push(1, 2, "...");
		}
	
		// Add middle pages
		for (
			let i = Math.max(1, currentPage - maxVisiblePages);
			i <= Math.min(totalPages, currentPage + maxVisiblePages);
			i++
		) {
			pageNumbers.push(i);
		}
	
		// Add the last pages
		if (currentPage < totalPages - maxVisiblePages) {
			pageNumbers.push("...", totalPages - 1, totalPages);
		}
	
		return (
			<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
				<button
					style={{
						padding: "2px 8px",
						margin: "2px",
						cursor: currentPage === 1 ? "not-allowed" : "pointer",
						backgroundColor: currentPage === 1 ? "#ccc" : "#007bff",
						color: "#fff",
						border: "none",
						borderRadius: "4px",
					}}
					onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
					disabled={currentPage === 1}
				>
					Previous
				</button>
				{pageNumbers.map((page, index) =>
					typeof page === "number" ? (
						<button
							style={{
								padding: "2px 8px",
								margin: "2px",
								cursor: currentPage === page ? "not-allowed" : "pointer",
								backgroundColor: currentPage === page ? "#6c757d" : "#007bff",
								color: "#fff",
								border: "none",
								borderRadius: "4px",
							}}
							key={index}
							onClick={() => setCurrentPage(page)}
							disabled={currentPage === page}
						>
							{page}
						</button>
					) : (
						<span
							key={index}
							style={{
								padding: "2px 8px",
								margin: "2px",
								color: "#6c757d",
								cursor: "default",
							}}
						>
							{page}
						</span>
					)
				)}
				<button
					style={{
						padding: "2px 8px",
						margin: "2px",
						cursor: currentPage === totalPages ? "not-allowed" : "pointer",
						backgroundColor: currentPage === totalPages ? "#ccc" : "#007bff",
						color: "#fff",
						border: "none",
						borderRadius: "4px",
					}}
					onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
					disabled={currentPage === totalPages}
				>
					Next
				</button>
			</Box>
		);
	};
	

	return (
		<Box>
			<ToastContainer position="top-center" autoClose={1500} />
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">User Messages List</Typography>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											User Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Email
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Mobile Number
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Messages
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Delete
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{messages.map((message) => (
									<TableRow key={message.id}>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{message.name}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{message.email}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{message.mobileNumber}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{message.message}
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "error.main",
													color: "#fff",
												}}
												size="small"
												label="Delete"
												onClick={() => handleDelete(message.id)}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{loading && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						)}
						{/* {error && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						)} */}
						{renderPagination()}
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
};

export default ViewMessages;
